import React, { Component } from "react";

class ConfirmationStep extends Component {
  handlePopupClose = () => {
    const { togglePopUp, setMessage, language, resetBookingHandler } =
      this.props;

    if (resetBookingHandler) {
      console.log("resetBookingHandler");
      console.log(resetBookingHandler);
      resetBookingHandler();
    }

    togglePopUp();
  };

  render() {
    return (
      <div className="popup">
        <div className="modal-backdrop in" style={{ opacity: "0.6" }}></div>

        <div
          className="modal fade show"
          aria-labelledby="myModalLabel"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="steps">
                  <div className="steps-row setup-panel">
                    <div className="step">
                      <span>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                    <div className="step">
                      <span>
                        <i className="fas fa-suitcase"></i>
                      </span>
                    </div>
                    <div className="step">
                      <span>
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <div className="step">
                      <span className={"current"}>
                        <i className="fas fa-check"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <a
                  role="button"
                  tabIndex="0"
                  className="close"
                  onClick={this.handlePopupClose}
                >
                  <span aria-hidden="true">&times;</span>
                </a>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="confirmation-step">
                    <img
                      alt="Happy"
                      src="https://img.icons8.com/officel/80/000000/lol.png"
                    />
                    <h2>Bedankt voor uw boeking</h2>
                    <p>
                      We hebben u een bevestigingsmail gestuurd.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationStep;
