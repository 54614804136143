import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CounterInput from 'react-counter-input';
import Loader from '../../tools/Loader';

class LuggageStep extends Component {
  static propTypes = {
    luggage: PropTypes.object.isRequired,

    setLuggage: PropTypes.func.isRequired,
    previousStep: PropTypes.func.isRequired,
  }

  state = {
    hasLuggage: false,
    isLoading: true,
    smallLuggage: 0,
    midLuggage: 0,
    largeLuggage: 0
  }

  translations = {
    dutch: {
      title: 'Heeft u baggage?',
      largeLuggage: 'Grote Koffer',
      midLuggage: 'Kleine Koffer',
      smallLuggage: 'Handbagage/Rugzak',
      yesButton: 'Ja',
      noButton: 'Nee',
      previousStep: 'Vorige Stap',
      nextStep: 'Volgende Stap',
    },
    english: {
      title: 'Do you have luggage?',
      largeLuggage: 'Large Suitcase',
      midLuggage: 'Small Suitcase',
      smallLuggage: 'Hand Luggage/Backpack',
      yesButton: 'Yes',
      noButton: 'No',
      previousStep: 'Previous Step',
      nextStep: 'Next Step',
    },
  };

  componentWillMount() {
    const { luggage } = this.props;

    if (luggage == null) {
      return this.setState({
        isLoading: false
      });
    }

    return this.setState({
      smallLuggage: luggage.smallLuggage,
      midLuggage: luggage.midLuggage,
      largeLuggage: luggage.largeLuggage,
      hasLuggage: (luggage.smallLuggage != null || luggage.midLuggage != null || luggage.largeLuggage != null),
      isLoading: false
    });
  }

  nextStep() {
    const { smallLuggage, midLuggage, largeLuggage } = this.state;
    const luggage = {
      smallLuggage,
      midLuggage,
      largeLuggage
    };

    this.props.setLuggage(luggage);
  }

  toggleLuggage(bool) {
    if (!bool) {
      this.nextStep();
    }

    this.setState({
      hasLuggage: bool
    });
  }

  renderLuggageOptions() {
    const { smallLuggage, midLuggage, largeLuggage } = this.state;
    const luggageText = this.translations[this.props.language || 'english'];

    return [
      <hr />,
      <div className="row luggage" key="luggage-options">
        <div className="col-12 col-md-4">
          <img alt="large suitcase" src="/images/grote-koffer.png" />
          <p>{luggageText.largeLuggage}</p>

          <div className="counter mx-auto">
            <CounterInput
              min={0}
              max={10}
              count={largeLuggage}
              onCountChange={count => this.setState({ largeLuggage: count})}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <img alt="small suitcase" src="https://img.icons8.com/color/96/000000/suitcase.png" />
          <p>{luggageText.midLuggage}</p>

          <div className="counter mx-auto">
            <CounterInput
              min={0}
              max={10}
              count={midLuggage}
              onCountChange={count => this.setState({ midLuggage: count})}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <img alt="backpack" src="https://img.icons8.com/color/96/000000/school-backpack.png" />
          <p>{luggageText.smallLuggage}</p>

          <div className="counter mx-auto">
            <CounterInput
              min={0}
              max={10}
              count={smallLuggage}
              onCountChange={count => this.setState({ smallLuggage: count})}
            />
          </div>
        </div>
      </div>
    ];
  }

  render() {
    const { hasLuggage, isLoading } = this.state;
    const luggageText = this.translations[this.props.language || 'english'];

    if (isLoading) {
      return (<Loader />);
    }

    return (
      <div className="luggage-step">
        <h2>{luggageText.title}</h2>

        <button className={`btn btn-primary no-luggage ${hasLuggage && 'active'}`} onClick={() => this.toggleLuggage(true)}>{luggageText.yesButton}</button>
        <button className={`btn btn-primary yes-luggage ${!hasLuggage && 'active'}`} onClick={() => this.toggleLuggage(false)}>{luggageText.noButton}</button>

        {hasLuggage && this.renderLuggageOptions()}
        <hr />

        <div className="steps">
          <button className="btn btn-primary float-left" onClick={() => this.props.previousStep()}>{luggageText.previousStep}</button>
          <button className="btn btn-primary float-right step-to-confirmation" onClick={() => this.nextStep()}>{luggageText.nextStep}</button>
        </div>
      </div>
    );
  }
}

export default LuggageStep;
