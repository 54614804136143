import React, { Component } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import AutoComplete from "./components/AutoComplete";
import PopUp from "./PopUp";

import ConfirmationStep from "./popup/ConfirmationStep";


class Booking extends Component {
  state = {
    date: "",
    returnDate: "",
    addressFullA: "",
    addressFullB: "",
    addressA: "",
    addressB: "",
    stops: [],
    popup: false,
    wantsReturn: false,
    messageSuccess: "",
    message: "",
    isDateValid: false,
    showConfirmation: false
  };

  constructor(props) {
    super(props);
    console.log("Props in constructor:", props.language);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const tripId = urlParams.get('trip');

      if (tripId) {
        this.checkPaymentStatus(tripId);
        urlParams.delete('trip');

        const newUrl = window.location.pathname;
        window.history.replaceState(null, '', newUrl);
      }
    }
  }

  checkPaymentStatus = (tripId) => {
    fetch(`/payment_check?trip=${tripId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({ showConfirmation: true });
        } else {
          this.setState({
            message: this.props.language === "dutch" ? "Sorry, er is wat fout gegaan probeer het later opnieuw" : "Sorry, something went wrong, please try again later"
          });
        }
      })
      .catch((error) => {
        console.error('Error checking payment status:', error);
        this.setState({
          message: this.props.language === "dutch" ? "Sorry, er is wat fout gegaan probeer het later opnieuw" : "Sorry, something went wrong, please try again later"
        });
      });
  };

  textContent = {
    english: {
      title: "Do you want to book a Taxi?",
      pickupAddress: "Pickup address",
      destinationAddress: "Destination address",
      pickupLabel: "When should we pick you up?",
      returnLabel: "Return Date",
      calculatePrice: "Calculate price",
      returnBooking: "I want to book a return",
      switchAddresses: "Switch addresses",
      addStop: "Add Stop",
      date: "Date",
    },
    dutch: {
      title: "Wilt u een Taxi boeken?",
      pickupAddress: "Ophaaladres",
      destinationAddress: "Bestemmings adres",
      pickupLabel: "Wanneer moeten we u ophalen?",
      returnLabel: "Retour Datum",
      calculatePrice: "Bereken prijs",
      returnBooking: "Ik wil retour boeken",
      switchAddresses: "Wissel adressen",
      addStop: "Voeg Stop Toe",
      date: "Datum",
    }
  };

  setMessage = (data) => {
    this.setState({ messageSuccess: data.success, message: data.message });
  };

  changeAddressA = (addressA, houseNumber) => {
    let addressFullA = addressA;

    if (houseNumber) {
      const splitAddress = addressA.split(",");
      splitAddress[0] = `${splitAddress[0]} ${houseNumber}`;
      addressFullA = splitAddress.join(",");
    }

    this.setState({ addressA, addressFullA });
  };

  changeAddressB = (addressB, houseNumber) => {
    let addressFullB = addressB;

    if (houseNumber) {
      const splitAddress = addressB.split(",");
      splitAddress[0] = `${splitAddress[0]} ${houseNumber}`;
      addressFullB = splitAddress.join(",");
    }

    this.setState({ addressB, addressFullB });
  };

  addStop = () => {
    this.setState((prevState) => ({
      stops: [...prevState.stops, { address: "", fullAddress: "" }],
    }));
  };

  getMinTime = (currentDate) => {
    return dayjs(currentDate).add(15, "minute");
  };

  removeStop = (index) => {
    this.setState((prevState) => {
      const stops = prevState.stops.filter((_, i) => i !== index);
      return { stops };
    });
  };

  changeStopAddress = (index, address, houseNumber) => {
    let fullAddress = address;
    if (houseNumber) {
      const splitAddress = address.split(",");
      splitAddress[0] = `${splitAddress[0]} ${houseNumber}`;
      fullAddress = splitAddress.join(",");
    }

    this.setState((prevState) => {
      const stops = [...prevState.stops];
      stops[index] = { address, fullAddress };
      return { stops };
    });
  };

  switchAddresses = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const newStops = prevState.stops.reverse(); // Reverse the stops
      return {
        addressA: prevState.addressB,
        addressB: prevState.addressA,
        addressFullA: prevState.addressFullB,
        addressFullB: prevState.addressFullA,
        stops: newStops,
      };
    });
  };

  getMinSelectableTime = () => {
    return dayjs().add(15, "minute");
  };

  changeDate = (date) => {
    const isDateValid = dayjs(date).isAfter(this.getMinSelectableTime());
    this.setState({ date, isDateValid });
  };

  changeReturnDate = (returnDate) => {
    const isDateValid = dayjs(returnDate).isAfter(this.getMinSelectableTime());
    this.setState({ returnDate, isDateValid });
  };

  togglePopup = () => {
    this.setState({ popup: !this.state.popup });
  };

  toggleConfirmation = () => {
    this.setState({ showConfirmation: !this.state.showConfirmation });
  }

  renderMessage() {
    const { message, messageSuccess } = this.state;
    return (
      <div
        className={`alert alert-${messageSuccess ? "success" : "danger"}`}
        role="alert"
      >
        {message}
      </div>
    );
  }

  resetBookingData = () => {
    this.setState({
      addressA: "",
      addressB: "",
      addressFullA: "",
      addressFullB: "",
      date: null,
      returnDate: null,
      stops: [],
      wantsReturn: false,
      isDateValid: false,
      popup: false,
    });
  };

  render() {
    const {
      date,
      returnDate,
      popup,
      addressA,
      addressB,
      addressFullA,
      addressFullB,
      stops,
      wantsReturn,
      message,
      isDateValid,
      showConfirmation
    } = this.state;

    const filterPassedTime = (time) => {
      const currentDate = new Date();
      const currentDateWithBuffer = new Date(currentDate.getTime() + 15 * 60 * 1000); // Add 15 minutes

      const selectedDate = new Date(time);
      return currentDateWithBuffer.getTime() < selectedDate.getTime();
    };

    // Get the language prop, default to English
    const language = this.props.language || "english";
    const text = this.textContent[language];

    if (popup) {
      return (
        <PopUp
          togglePopUp={this.togglePopup}
          setMessage={this.setMessage}
          addressA={addressFullA}
          addressB={addressFullB}
          stops={stops.map((stop) => stop.fullAddress)} // Pass full addresses of stops
          date={date}
          returnDate={returnDate}
          wantsReturn={wantsReturn}
          language={language}
          resetBookingData={this.resetBookingData}
        />
      );
    }

    if (showConfirmation) {
      return (
        <ConfirmationStep
          togglePopUp={this.toggleConfirmation}
          setMessage={this.setMessage}
          language={language}
        />
      );
    }

    return (
      <div className="booking-box">
        {message && this.renderMessage()}

        <form>

          {this.props.big_title ? (
            <h1 className="text-center" style={{ fontSize: 25 }}>
              {text.title}
            </h1>
          ) : (
            <h3 className="text-center">{text.title}</h3>
          )
          }

          <button
            className="btn btn-primary switch-button"
            onClick={(e) => this.switchAddresses(e)}
          >
            <i className="fa fa-exchange fa-rotate-90" aria-hidden="true" />
          </button>

          <div className="address-group">
            <div className="form-group">
              <i className="fas fa-map-marker"></i>
              <AutoComplete
                type={text.pickupAddress}
                address={addressA}
                handleSelect={this.changeAddressA}
                showCurrentLocation={true}
                googleAPiKey={this.props.google_api_key}
                language={language}
              />
            </div>

            {stops.map((stop, index) => (
              <div
                key={index}
                style={{ position: "relative", marginBottom: "15px" }}
              >
                <AutoComplete
                  type={`Stop ${index + 1}`}
                  address={stop.address}
                  handleSelect={(address) =>
                    this.changeStopAddress(index, address)
                  }
                  showCurrentLocation={false}
                  style={{ width: "100%" }} // Ensures the input takes the full width
                />
                <button
                  className="btn"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "21px", // Adjust this to position correctly over the input
                    transform: "translateY(-50%)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    padding: 0,
                    backgroundColor: "#F5BA0B",
                    border: "none",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={() => this.removeStop(index)}
                  aria-label="Remove Stop"
                >
                  <i
                    className="fas fa-times"
                    style={{ fontSize: "16px", color: "white" }}
                  ></i>
                </button>
              </div>
            ))}

            <div className="form-group">
              <i className="fas fa-map-marker-alt"></i>

              <AutoComplete
                type={text.destinationAddress}
                address={addressB}
                handleSelect={this.changeAddressB}
                language={language}
              />
            </div>

            <button
              className="btn main-button"
              style={{ backgroundColor: "#F5BA0B", borderColor: "#F5BA0B", color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                this.addStop();
              }}
            >
              {text.addStop}
            </button>
          </div>

          <hr />

          <div className="">
            <label>{text.pickupLabel}</label>
            <DatePicker
              showTimeSelect
              timeFormat="HH:mm"
              dateFormat="d MMMM, HH:mm"
              showIcon
              icon={<i className="fas fa-calendar-day" style={{ fontSize: '16px', color: '#F5BA0B', top: "2px", left: "2px" }} />}
              placeholderText={this.props.language === "dutch" ? "Datum" : "Date"}
              selected={date}
              onChange={(selectedDate) => {
                const minSelectableTime = this.getMinTime(new Date()).toDate();
                if (selectedDate < minSelectableTime) {
                  this.changeDate(minSelectableTime);
                } else {
                  this.changeDate(selectedDate);
                }
              }}
              minDate={new Date()}
              filterTime={filterPassedTime}
              popperPlacement="top-start"
              timeIntervals={15}
              disabledKeyboardNavigation={false}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              withPortal
            />
            {wantsReturn && (
              <div className="mt-2">
                <DatePicker
                  showIcon
                  icon={<i className="fas fa-calendar-day" style={{ fontSize: '16px', color: '#F5BA0B', top: "2px", left: "2px" }} />}
                  placeholderText={this.props.language === "dutch" ? "Retour Datum" : "Return Date"}
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="d MMMM, HH:mm"
                  selected={returnDate}
                  onChange={(selectedDate) => {
                    const minSelectableTime = this.getMinTime(
                      new Date()
                    ).toDate();
                    if (selectedDate < minSelectableTime) {
                      this.changeReturnDate(minSelectableTime);
                    } else {
                      this.changeReturnDate(selectedDate);
                    }
                  }}
                  minDate={date && new Date(date)}
                  popperPlacement="top-start"
                  timeIntervals={15}
                  withPortal
                  disabledKeyboardNavigation={false}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            )}

            <div style={{ marginTop: 10 }} className="checkbox">
              <input
                type="checkbox"
                name="returnDate"
                checked={wantsReturn}
                onClick={() => this.setState({ wantsReturn: !wantsReturn })}
              />
              <label className="ml-1">{text.returnBooking}</label>
            </div>
          </div>

          <button
            type="submit"
            onClick={this.togglePopup}
            disabled={!addressFullA || !addressFullB || !date || !isDateValid}
            className={`btn btn-primary main-button ${!addressFullA || !addressFullB || !date || !isDateValid
              ? "button-disabled"
              : "button-enabled"
              }`}
          >
            {text.calculatePrice}
          </button>
        </form>
      </div>
    );
  }
}

export default Booking;
