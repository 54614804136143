import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PlacesAutocomplete from 'react-places-autocomplete';

class AutoComplete extends Component {
  static propTypes = {
    address: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleSelect: PropTypes.func.isRequired,
    showCurrentLocation: PropTypes.bool,
  }

  state = {
    hasHousenumber: true,
    houseNumber: '',
    isStreet: false,
    isCurrentLocationVisible: false,
    loading: false
  }

  setHouseNumber = (houseNumber) => {
    this.setState({ houseNumber });

    this.checkHasHouseNumber(this.props.address, houseNumber);
  }

  checkHasHouseNumber = (address, houseNumberNew) => {
    const { isStreet } = this.state;
    let { houseNumber } = this.state;

    if (houseNumberNew != null) {
      houseNumber = houseNumberNew;
    }
    const hasHousenumber = ((address.substring(3)).match('.*\\d.*') && address !== '');

    this.setState({hasHousenumber});

    this.props.handleSelect(address, (!hasHousenumber && isStreet) ? houseNumber : null);
  }

  checkPlaceType = (address, placeId, suggestion) => {

    this.setState({ isStreet: suggestion.types.includes('route') });
    this.checkHasHouseNumber(address);
  }

  handleInputChange = (address) => {
    this.setState({
      isCurrentLocationVisible: false,
    });
    this.props.handleSelect(address);
  };

  handleInputFocus = () => {
    const { showCurrentLocation, address } = this.props;
    if (showCurrentLocation && address.trim() === '') {
      this.setState({ isCurrentLocationVisible: true });
    }
  };

  handleInputBlur = () => {
    setTimeout(() => this.setState({ isCurrentLocationVisible: false }), 200);
  };

  getCurrentLocation = () => {
    this.setState({ loading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const googleApiKey = this.props.googleAPiKey;
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleApiKey}`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              this.setState({ loading: false });
              if (data.status === "OK") {
                const currentLocation = data.results[0]?.formatted_address || "Unknown Location";
                this.props.handleSelect(currentLocation);
              } else {
                console.error("Failed to fetch location. Please try again.");
              }
            })
            .catch((error) => {
              console.error("Error fetching location:", error);
              this.setState({ loading: false });
            });
        },
        (error) => {
          console.error('Error fetching location:', error);
          this.setState({ loading: false });
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      this.setState({ loading: false });
    }
  };

  render() {
    const { type, address, language } = this.props;
    const { hasHousenumber, isStreet, houseNumber, isCurrentLocationVisible, loading } = this.state;

    const searchOptions = {
      componentRestrictions: {country: ['nl'] }
    };

    const loadingText = loading
      ? language === 'dutch'
        ? 'Locatie ophalen...'
        : 'Retrieving location...'
      : '';

    return (
      <div className="dropdown">
        <PlacesAutocomplete
          value={address}
          onChange={this.handleInputChange}
          onSelect={this.checkPlaceType}
          searchOptions={searchOptions}
          shouldFetchSuggestions={address.length > 1}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div className={`${!hasHousenumber && isStreet && 'set-display-inline'}`}>
              <div className="relative">
                <input
                  {...getInputProps({
                    placeholder: (this.props.showCurrentLocation && loading) ? loadingText : type,
                    className: 'form-control',
                    onFocus: this.handleInputFocus,
                    onBlur: this.handleInputBlur,
                  })}
                />
                {this.props.showCurrentLocation && loading && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '10px',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                  </div>
                )}
              </div>
              <div
                className="dropdown-menu"
                style={suggestions.length > 0 || isCurrentLocationVisible ? { display: 'block' } : {}}
              >
                {isCurrentLocationVisible && (
                  <div
                    className="dropdown-item"
                    onClick={this.getCurrentLocation}
                    style={{ cursor: 'pointer' }}
                  >
                    <b>Current location</b>
                  </div>
                )}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active ? 'dropdown-item active' : 'dropdown-item';

                  return (
                    <div {...getSuggestionItemProps(suggestion, { className })}>
                      <span>
                        <p><b>{suggestion.formattedSuggestion.mainText}</b></p>
                        <p>{suggestion.formattedSuggestion.secondaryText}</p>
                        <hr />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        {(!hasHousenumber && isStreet) && <input type="text" value={houseNumber} onChange={e => this.setHouseNumber(e.target.value)} className="form-control house-no" placeholder="Huis nr." />}
      </div>
    );
  }
}

export default AutoComplete;
