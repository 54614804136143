import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import * as EmailValidator from 'email-validator';

import Loader from '../../tools/Loader';

class DetailStep extends Component {
  static propTypes = {
    bookStep: PropTypes.func.isRequired,
    previousStep: PropTypes.func.isRequired,
    taxiType: PropTypes.string.isRequired,
    addressA: PropTypes.string.isRequired,
    addressB: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    returnDate: PropTypes.object.isRequired,
    wantsReturn: PropTypes.bool.isRequired,
  }

  state = {
    fullName: '',
    email: '',
    amountPerson: 1,
    phoneNumber: '',
    remark: '',
    flightNumber: '',
    paymentType: 'online', // New state for payment type
    message: '',
    isLoading: false,
  }

  translations = {
    english: {
      fullNamePlaceholder: 'Full name (required)',
      emailPlaceholder: 'Email (required)',
      phoneNumberPlaceholder: 'Phone number (required)',
      amountPersonPlaceholder: 'Number of people',
      flightNumberPlaceholder: 'Flight number (optional)',
      remarkPlaceholder: 'Remark',
      paymentTypePlaceholder: 'Payment method',
      confirmBooking: 'Confirm booking',
      change: 'Change',
      messageSuccess: 'Success',
      messageError: 'Error',
      from: 'From: ',
      to: 'To: ',
      date: 'Date: ',
      returnDate: 'Return Date: ',
      yourInfo: 'Your Information',
      car: 'Car',
      bus: 'Van',
      total: 'Total: ',
    },
    dutch: {
      fullNamePlaceholder: 'Volledige naam (verplicht)',
      emailPlaceholder: 'E-mail (verplicht)',
      phoneNumberPlaceholder: 'Telefoonnummer (verplicht)',
      amountPersonPlaceholder: 'Aantal personen',
      flightNumberPlaceholder: 'Vluchtnummer (optioneel)',
      remarkPlaceholder: 'Opmerking',
      paymentTypePlaceholder: 'Betaalmethode',
      confirmBooking: 'Bevestig boeking',
      change: 'Wijzig',
      messageSuccess: 'Succes',
      messageError: 'Fout',
      from: 'Van: ',
      to: 'Naar: ',
      date: 'Datum: ',
      returnDate: 'Retour Datum: ',
      yourInfo: 'Uw Gegevens',
      car: 'Auto',
      bus: 'Busje',
      total: 'Totaal: ',
    },
  };

  goToBook() {
    const { fullName, email, phoneNumber, amountPerson, remark, flightNumber, paymentType } = this.state;
    const { bookStep } = this.props;

    if (fullName === '') {
      this.setState({
        messageSuccess: false,
        message: 'Voer een volledige naam in'
      });
      return;
    }

    if (phoneNumber.length < 4) {
      this.setState({
        messageSuccess: false,
        message: 'Voer een geldige telefoonnummer in'
      });
      return;
    }

    if (EmailValidator.validate(email)) {
      this.setState({ isLoading: true });
      bookStep(fullName, email, phoneNumber, amountPerson, remark, flightNumber, paymentType);
    } else {
      this.setState({
        messageSuccess: false,
        message: 'Voer een geldige email in'
      });
    }
  }

  renderMessage() {
    const { message, messageSuccess } = this.state;

    return (
      <div className={`alert alert-${messageSuccess ? 'success' : 'danger'}`} role="alert">
        {message}
      </div>
    );
  }

  render() {
    const { price, taxiType, addressA, addressB, date, wantsReturn, returnDate } = this.props;
    const { message, isLoading, paymentType } = this.state;
    const luggageText = this.translations[this.props.language || 'english'];

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="customer-details">
        <div className="modal-body">
          <div className="row">
            <div className="col-12 col-md-6 summary-container">
              <div className="summary">
                <div className="text-center">
                  {taxiType === 'car' ? (
                    <>
                      <img alt="car" src="https://img.icons8.com/color/96/000000/taxi.png" />
                      <b>{luggageText.car}</b>
                    </>
                  ) : (
                    <>
                      <img alt="bus" src="https://img.icons8.com/color/96/000000/bus2.png" />
                      <b>{luggageText.bus}</b>
                    </>
                  )}
                </div>
                <hr />
                <p><b>{luggageText.from}</b>{addressA}</p>
                <p><b>{luggageText.to}</b>{addressB}</p>
                <p><b>{luggageText.date}</b>{format(date, 'dd-MM-yyyy, HH:mm')}</p>
                <p><b>{luggageText.total}</b> €&nbsp;{price}</p>
                {wantsReturn && (
                  <p><b>{luggageText.returnDate}</b>{format(returnDate, 'dd-MM-yyyy, HH:mm')}</p>
                )}
                <button className="btn btn-primary" onClick={this.props.previousStep}>
                  {luggageText.change}
                </button>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form">
                {message && this.renderMessage()}
                <p>
                  <b className="your-info">{luggageText.yourInfo}</b>
                </p>
                <div className="form-group">
                  <i className="fas fa-user"></i>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(n) => this.setState({ fullName: n.target.value })}
                    placeholder={luggageText.fullNamePlaceholder}
                  />
                </div>
                <div className="form-group">
                  <i className="fas fa-envelope"></i>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder={luggageText.emailPlaceholder}
                  />
                </div>
                <div className="form-group">
                  <i className="fas fa-phone fa-rotate-90"></i>
                  <input
                    type="tel"
                    className="form-control"
                    onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                    placeholder={luggageText.phoneNumberPlaceholder}
                  />
                </div>
                <div className="form-group">
                  <i className="fas fa-users"></i>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => this.setState({ amountPerson: e.target.value })}
                    placeholder={luggageText.amountPersonPlaceholder}
                  />
                </div>

                <div className="form-group">
                  <i className="fas fa-plane"></i>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => this.setState({ flightNumber: e.target.value })}
                    placeholder={luggageText.flightNumberPlaceholder}
                  />
                </div>

                {/* New Payment Type Selector */}
                <div className="form-group">
                  <label className="d-block mb-2">
                    {luggageText.paymentTypePlaceholder || "Payment Type"}
                  </label>
                  <div className="d-flex" role="group" aria-label="Payment Type">
                    <button
                      type="button"
                      className={`btn ${this.state.paymentType === 'online' ? 'text-white' : 'text-warning'
                        } d-flex align-items-center justify-content-center`}
                      style={{
                        backgroundColor: this.state.paymentType === 'online' ? '#F5BA0B' : 'white',
                        color: this.state.paymentType === 'online' ? 'white' : '#F5BA0B',
                        border: '1px solid #F5BA0B',
                        width: '50%',
                        height: '45px',
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                        boxShadow: 'none',
                        outline: 'none',
                        fontSize: '14px',
                        fontWeight: '700'
                      }}
                      onClick={() => this.setState({ paymentType: 'online' })}
                    >
                      {this.props.language === 'dutch' ? 'Online Betaling' : 'Online'}
                    </button>
                    <button
                      type="button"
                      className={`btn ${this.state.paymentType === 'cash' ? 'text-white' : 'text-warning'
                        } d-flex align-items-center justify-content-center`}
                      style={{
                        backgroundColor: this.state.paymentType === 'cash' ? '#F5BA0B' : 'white',
                        color: this.state.paymentType === 'cash' ? 'white' : '#F5BA0B',
                        border: '1px solid #F5BA0B',
                        width: '50%',
                        height: '45px',
                        borderTopRightRadius: '5px',
                        borderBottomRightRadius: '5px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        boxShadow: 'none',
                        outline: 'none',
                        fontSize: '14px',
                        fontWeight: '700'
                      }}
                      onClick={() => this.setState({ paymentType: 'cash' })}
                    >
                      {this.props.language === 'dutch' ? 'Contant Geld/Pin' : 'Cash'}
                    </button>
                  </div>
                </div>

                <div className="form-group">
                  <label>{luggageText.remarkPlaceholder}</label>
                  <textarea
                    className="form-control"
                    onChange={(e) => this.setState({ remark: e.target.value })}
                    placeholder={luggageText.remarkPlaceholder}
                  />
                </div>
                <button className="btn btn-primary book-taxi-button" onClick={() => this.goToBook()}>
                  {luggageText.confirmBooking}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailStep;
