import React, { Component } from "react";
import PropTypes from "prop-types";

import { fetchPOST } from "../../tools/fetch";

import { appUrl } from "../../tools/settings";
import Loader from "../../tools/Loader";

class PriceStep extends Component {
  static propTypes = {
    setTaxiPrice: PropTypes.func.isRequired,
    togglePopUp: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,

    addressA: PropTypes.string.isRequired,
    addressB: PropTypes.string.isRequired,
    wantsReturn: PropTypes.bool.isRequired,
  };

  state = {
    isLoading: true,
    fixedPrice: false,
    carPrice: 0,
    busPrice: 0,
  };

  translations = {
    dutch: {
      fixedPrice: "Vaste Tarief",
      car: "Auto",
      bus: "Busje",
      carDetails: ["Tot 4 personen", "Genoeg ruimte"],
      busDetails: ["Tot 8 personen", "Meer ruimte voor baggage"],
      price: "Prijs: € ",
      lowPriceMessage: "Sorry, de rit is te kort neem contact op met centrale",
      errorMessage: "Sorry, er is wat fout gegaan probeer het later opnieuw",
      select: "Selecteer"
    },
    english: {
      fixedPrice: "Fixed Price",
      car: "Car",
      bus: "Van",
      carDetails: ["Up to 4 people", "Enough space"],
      busDetails: ["Up to 8 people", "More space for luggage"],
      price: "Price: € ",
      lowPriceMessage:
        "Sorry, the ride is too short. Please contact the center.",
      errorMessage: "Sorry, something went wrong. Please try again later.",
      select: "Select"
    },
  };

  componentDidMount() {
    const {
      addressA,
      addressB,
      wantsReturn,
      togglePopUp,
      setMessage,
      language,
      stops
    } = this.props;

    fetchPOST(
      `${appUrl}/price`,
      {
        address_a: addressA,
        address_b: addressB,
        has_return: wantsReturn,
        stops: stops
      },
      {
        stateContext: this,
        onSuccess: (json) => {
          // this.calculatePrice(json);
          this.setState({
            carPrice: json.car_price,
            busPrice: json.bus_price,
            fixedPrice: json.fixed_price,
          });
        },
        onError: (error) => {
          if (error == "low price") {
            if (language == "dutch") {
              setMessage({
                success: false,
                message:
                  "Sorry, de rit is te kort neem contact op met centrale",
              });
            } else {
              setMessage({
                success: false,
                message: "Sorry, the ride is too short, please contact central",
              });
            }
            togglePopUp();
          } else {
            if (language == "dutch") {
              setMessage({
                success: false,
                message:
                  "Sorry, er is wat fout gegaan probeer het later opnieuw",
              });
            } else {
              setMessage({
                success: false,
                message: "Sorry, something went wrong, please try again later",
              });
            }
            togglePopUp();
          }
        },
      }
    );
  }

  onSelected(type, price) {
    this.props.setTaxiPrice(type, price);
  }

  renderFixedPrice() {
    return <li className="fixed-price">Vaste Tarief</li>;
  }

  render() {
    const { carPrice, busPrice, fixedPrice, isLoading } = this.state;
    const taxiTypeText = this.translations[this.props.language || "english"];

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 taxi-type-container">
                <div className="taxi-type">
                  <img
                    alt="car"
                    src="https://img.icons8.com/color/96/000000/taxi.png"
                  />
                  <p>
                    <b>{taxiTypeText.car}</b>
                  </p>

                  <ul>
                    {taxiTypeText.carDetails.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                    {fixedPrice && this.renderFixedPrice()}
                  </ul>

                  <p className="price">
                    {taxiTypeText.price}
                    {carPrice},-
                  </p>

                  <button
                    className="btn btn-primary car-type-select"
                    onClick={() => this.onSelected("car", carPrice)}
                  >
                    {taxiTypeText.select}
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-6 taxi-type-container">
                <div className="taxi-type">
                  <img
                    alt="bus"
                    src="https://img.icons8.com/color/96/000000/bus2.png"
                  />
                  <p>
                    <b>{taxiTypeText.bus}</b>
                  </p>

                  <ul>
                    {taxiTypeText.busDetails.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                    {fixedPrice && this.renderFixedPrice()}
                  </ul>

                  <p className="price">
                    {taxiTypeText.price}
                    {busPrice},-
                  </p>

                  <button
                    className="btn btn-primary bus-type-select"
                    onClick={() => this.onSelected("bus", busPrice)}
                  >
                    {taxiTypeText.select}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PriceStep;
